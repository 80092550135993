import { alpha, createTheme } from '@mui/material/styles';

import MuiButton from 'assets/themes/components/MuiButton';
import MuiCheckbox from 'assets/themes/components/MuiCheckbox';
import MuiChip from 'assets/themes/components/MuiChip';
import MuiFilledInput from 'assets/themes/components/MuiFilledInput';
import {
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
} from 'assets/themes/components/MuiForm';
import MuiIconButton from 'assets/themes/components/MuiIconButton';
import MuiInputBase from 'assets/themes/components/MuiInputBase';
import { MuiMenuItem, MuiMenu } from 'assets/themes/components/MuiMenu';
import MuiOutlinedInput from 'assets/themes/components/MuiOutlinedInput';
import { MuiPaginationItem, MuiPagination } from 'assets/themes/components/MuiPagination';
import MuiRadio from 'assets/themes/components/MuiRadio';
import MuiSwitch from 'assets/themes/components/MuiSwitch';
import MuiTab from 'assets/themes/components/MuiTab';
import MuiTabs from 'assets/themes/components/MuiTabs';
import MuiTooltip from 'assets/themes/components/MuiTooltip';
import customerPalette from 'assets/themes/customerPalette';
import operatorPalette from 'assets/themes/operatorPalette';
import { variants } from 'assets/themes/variants';

const getTheme = (isOperator: boolean) => createTheme({
  typography: {
    fontFamily: 'Nunito Sans, sans-serif',
    h1: variants.h1,
    h2: variants.h2,
    subtitle1: variants.subtitle1,
    body1: variants.body1,
    body2: variants.body2,
    body3: variants.body3,
    label1: variants.label1,
    label2: variants.label2,
    label3: variants.label3,
    table: variants.table,
    button: {
      textTransform: 'none',
    },
  },
  palette: isOperator ? operatorPalette : customerPalette,
  spacing: 4,
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: ({ theme: muiTheme }: any) => ({
          color: muiTheme.palette.general.lightGrey6,
          marginLeft: muiTheme.spacing(3),
          marginRight: muiTheme.spacing(3),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme: muiTheme }: any) => ({
          borderRadius: '0px',
          border: `1px solid ${muiTheme.palette.border.secondary}`,

          '&.small': {
            padding: muiTheme.spacing(3),
          },
          '&.medium': {
            padding: muiTheme.spacing(5, 6),
          },
          '&.large': {
            padding: muiTheme.spacing(7, 9),
          },
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '27px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: () => ({
          '& .MuiDialog-paper': {
            border: 'none',
          },
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: ({ theme: muiTheme }: any) => ({
          backgroundColor: muiTheme.palette.background.secondary,
          borderRadius: '6px',
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root.MuiInputBase-sizeSmall': {
            padding: '8.5px 14px',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '&.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
            marginTop: 0,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme: muiTheme }) => ({
          borderColor: muiTheme.palette.border.secondary,
          '&.light': {
            borderColor: alpha(muiTheme.palette.border.secondary, 0.4),
          },
        }),
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '& .MuiDialog-backdrop': {
            backgroundColor: 'rgba(188, 188, 188, 0.4)',
            backdropFilter: 'blur(4px)',
          },
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'active' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.general.lightGreen1,
            border: 0,
          }),
        },
        {
          props: { variant: 'success' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.success.main,
            color: muiTheme.palette.common.white,
            border: 0,
          }),
        },
        {
          props: { variant: 'inactive' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.general.lightGrey3,
            color: muiTheme.palette.text.primary,
            border: 0,
          }),
        },
        {
          props: { variant: 'disabled' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.background.main,
            color: muiTheme.palette.general.lightGrey6,
            border: 0,
          }),
        },
        {
          props: { variant: 'lightPending' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.general.lightYellow1,
            border: 0,
          }),
        },
        {
          props: { variant: 'pending' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.general.lightYellow2,
            border: 0,
          }),
        },
        {
          props: { variant: 'new' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.common.white,
            border: `1px solid ${muiTheme.palette.primary.main}`,
            color: muiTheme.palette.primary.main,
          }),
        },
        {
          props: { variant: 'error' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.general.lightRed,
            border: 0,
          }),
        },
        {
          props: { variant: 'recurrence' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.general.lightBlue1,
            border: 0,
          }),
        },
        {
          props: { variant: 'outlined' },
          style: ({ theme: muiTheme }: any) => ({
            backgroundColor: muiTheme.palette.common.white,
            border: `1px solid ${muiTheme.palette.border.secondary}`,
            borderRadius: '20px',
            padding: muiTheme.spacing(2, 5),
            height: '37px',
            cursor: 'pointer',

            '& span': {
              fontSize: '15px',
              lineHeight: '21px',
              color: muiTheme.palette.general.lightBlack,
              padding: 0,
            },

            '&:hover': {
              border: `1px solid ${muiTheme.palette.general.darkGrey1}`,
              backgroundColor: muiTheme.palette.background.main,
            },
          }),
        },
      ],
      ...MuiChip,
    },
    MuiOutlinedInput,
    MuiFilledInput,
    MuiButton,
    MuiTabs,
    MuiTab,
    MuiCheckbox,
    MuiRadio,
    MuiSwitch,
    MuiPagination,
    MuiPaginationItem,
    MuiFormControlLabel,
    MuiFormHelperText,
    MuiFormLabel,
    MuiInputBase,
    MuiMenu,
    MuiMenuItem,
    MuiTooltip,
    MuiIconButton,
  },
});

export default getTheme;
