import { Box, IconButton } from '@mui/material';
import { isEqual } from 'lodash';
import {
  FC, ReactNode, useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import CustomButton from 'components/CustomButton';
import FilterHeader from 'containers/AdvancedAccountFilter/FilterHeader';

import { useAppSelector } from 'hooks/useRedux';
import { accountsSelector } from 'store/bank-accounts/selectors';

import ChevronUpIcon from 'assets/icons/ChevronUpIcon';
import { CashFlowFilterHeader } from 'models/cashFlow.interface';
import { GenericOption } from 'models/option.interface';

import {
  Accordion, AccordionDetails, AccordionSummary,
} from './index.styled';

interface AdvancedAccountFilterProps {
  accounts: GenericOption[];
  children?: ReactNode;
  activeBankAccount?: string;
  setActiveBankAccount?: (account: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
  onResetAll: () => void;
  disabled: boolean;
  isExpanded: boolean;
  onSetIsExpanded: (isExpanded: boolean) => void;
  filters?: CashFlowFilterHeader;
  asChip?: boolean;
  hasResetAll?: boolean;
}

const AdvancedAccountFilter: FC<AdvancedAccountFilterProps> = ({
  children,
  onSubmit,
  onCancel,
  onResetAll,
  accounts,
  disabled,
  isExpanded,
  onSetIsExpanded,
  filters = {} as CashFlowFilterHeader,
  asChip = false,
  activeBankAccount,
  setActiveBankAccount,
  hasResetAll = false,
}) => {
  const intl = useIntl();
  const { data: bankAccounts } = useAppSelector(accountsSelector, isEqual);
  const displayAccounts = useMemo(() => {
    const ids = accounts?.map((acc) => acc?.value).filter((value) => value);
    return bankAccounts?.filter(({ id }) => ids.includes(id)) || [];
  }, [bankAccounts, accounts]);

  const toggleAccordion = () => onSetIsExpanded(!isExpanded);

  return (
    <Accordion
      defaultExpanded
      expanded={isExpanded}
      onChange={toggleAccordion}
      square
      disableGutters
    >
      <AccordionSummary isExpanded={isExpanded}>
        <FilterHeader
          accounts={displayAccounts}
          asChip={asChip}
          activeBankAccount={activeBankAccount}
          setActiveBankAccount={setActiveBankAccount}
          disabled={isExpanded}
          filters={filters}
        />
        <IconButton onClick={toggleAccordion}>
          <ChevronUpIcon size={24} />
        </IconButton>
      </AccordionSummary>

      <AccordionDetails expanded={isExpanded}>
        <Box>
          {children}
          <Box display="flex" alignItems="center" justifyContent={hasResetAll ? 'space-between' : 'flex-end'}>
            {hasResetAll && (
              <CustomButton
                variant="text"
                className="cancel"
                color="error"
                label={intl.formatMessage({ id: 'button.resetAll' })}
                id="button-resetAll"
                onClick={onResetAll}
                sx={{ color: 'general.darkRed1' }}
              />
            )}

            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Box display="flex" gap={2}>
                <CustomButton
                  className="cancel"
                  variant="text"
                  label={intl.formatMessage({ id: 'button.cancel' })}
                  id="button-cancel"
                  onClick={onCancel}
                />
                <CustomButton
                  label={intl.formatMessage({ id: 'button.saveAndApply' })}
                  id="button-save"
                  onClick={onSubmit}
                  disabled={disabled}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdvancedAccountFilter;
