import { Box } from '@mui/material';
import { isEqual } from 'lodash';

import EmptySearchResults from 'components/EmptySearchResults';
import ResponsiveTable from 'components/Table';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setSummaryTransactionSort } from 'store/cash-flow/cashFlowSlice';
import {
  isLoadingSummaryTransactionsSelector,
  summaryTransactionsSelector,
  summaryTransactionsSortOptionsSelector,
} from 'store/cash-flow/selectors';

import { INSIGHTS_SUMMARY_COLUMNS } from 'constants/tableColumns';
import { getRequestSortOptions } from 'utils/sort';

const SummaryTransactions = () => {
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(summaryTransactionsSelector, isEqual);
  const isLoading = useAppSelector(isLoadingSummaryTransactionsSelector, isEqual);
  const sortOptions = useAppSelector(summaryTransactionsSortOptionsSelector, isEqual);

  const handleSort = (columnName: string) => {
    const newSortOption = getRequestSortOptions(columnName, sortOptions);
    dispatch(setSummaryTransactionSort(newSortOption));
  };

  return (
    <Box mt={4}>
      <ResponsiveTable
        isFullWidth
        id="table-transactions"
        columns={INSIGHTS_SUMMARY_COLUMNS}
        data={transactions}
        isRounded
        isLoading={isLoading}
        sortOptions={sortOptions}
        onSort={handleSort}
      />

      {!transactions?.length && <EmptySearchResults />}
    </Box>
  );
};

export default SummaryTransactions;
