import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Grid2 as Grid, Box } from '@mui/material';
import { isEqual } from 'lodash';
import {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import CustomButton from 'components/CustomButton';
import InsightsEmptyState from 'components/InsightsEmptyState';
import AdvancedAccountFilter from 'containers/AdvancedAccountFilter';
import AccountsFilter from 'containers/AdvancedAccountFilter/AccountsFilter';
import SearchField from 'fields/SearchField';
import TotalTransactionsVolume from 'modules/insights-advanced-search/components/TotalTransactionsVolume';
import FilterContent from 'modules/insights-advanced-search/containers/FilterContent';
import Transactions from 'modules/insights-advanced-search/containers/Transactions';

import { useInsightsFilters } from 'hooks/useInsightsFilters';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getAdvancedTransactionSearchDocument } from 'services/CashFlowService';
import { saveUserFilterPreferences } from 'services/UserService';
import { accountsSelector } from 'store/bank-accounts/selectors';
import { resetCashFlow, setAdvancedSearchSearch } from 'store/cash-flow/cashFlowSlice';
import { advancedSearchPaginationSelector, advancedSearchSearchSelector } from 'store/cash-flow/selectors';
import { currentWorkspaceSelector, userSelector } from 'store/user/selectors';

import DownloadIcon from 'assets/icons/DownloadIcon';
import { DEFAULT_FILTERS } from 'constants/cashflow';
import { DefaultType, InsightsComponents } from 'constants/enums';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { GenericOption } from 'models/option.interface';
import { formatFiltersForHeader, formatFiltersForServer } from 'modules/insights-advanced-search/utils';
import { forceDownload } from 'utils/downloadFile';
import { handleApiErrors } from 'utils/errorUtils';
import {
  advancedTransactionFilterSchema,
  AdvancedTransactionFilterSchema,
} from 'utils/validation/transactionFormSchema';

import { SearchWrapper } from './index.styled';

const AdvancedTransactionSearch = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const loggedUser = useAppSelector(userSelector);
  const workspaceId = currentWorkspace?.id || '';

  const search = useAppSelector(advancedSearchSearchSelector);
  const { data: bankAccounts, isLoading } = useAppSelector(accountsSelector, isEqual);
  const pagination = useAppSelector(advancedSearchPaginationSelector);

  const [expanded, setExpanded] = useState<boolean>(false);
  const {
    tempAccounts,
    setTempAccounts,
    selectedAccounts,
    setSelectedAccounts,
    filters,
    setFilters,
    onResetAll,
    hasSavedFilters,
    setHasSavedFilters,
  } = useInsightsFilters<AdvancedTransactionFilterSchema>({
    defaultFilters: DEFAULT_FILTERS,
    pageName: InsightsComponents.advancedSearch,
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<AdvancedTransactionFilterSchema>({
    resolver: zodResolver(advancedTransactionFilterSchema),
    mode: 'onSubmit',
    defaultValues: DEFAULT_FILTERS,
  });

  useEffect(() => {
    reset(filters);
  }, [filters]);

  useEffect(() => () => {
    dispatch(resetCashFlow());
  }, []);

  const onSubmit = (newFilters: AdvancedTransactionFilterSchema) => {
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
      reset(newFilters);
    }

    const newSelectedAccounts = [...tempAccounts.filter((account) => account?.value) as GenericOption[]];

    if (!isEqual(selectedAccounts, tempAccounts)) {
      setSelectedAccounts(newSelectedAccounts);
    }

    setExpanded(false);

    if (!loggedUser?.id) {
      toast.error(intl.formatMessage({ id: 'error.missingInfoPreferences' }), GENERAL_TOAST_OPTIONS);
      return;
    }

    try {
      const hasAllOptionSelected = newSelectedAccounts.find(({ value }) => value === DefaultType.all);
      const payloadFilters: Record<string, any> = {
        bankAccountIds: hasAllOptionSelected
          ? bankAccounts?.map(({ id }) => id).join(',') || ''
          : newSelectedAccounts.map(({ value }) => value).join(','),
        ...formatFiltersForServer(newFilters),
      };
      const payload = {
        pageName: InsightsComponents.advancedSearch,
        filter: Object.entries(payloadFilters)?.map(([element, value]) => ({ element, value })),
      };

      saveUserFilterPreferences(workspaceId, loggedUser?.id, payload);
      setHasSavedFilters(true);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const isFormDirty = useMemo(() => {
    if (isDirty) {
      return true;
    }

    const newIds = tempAccounts?.map((acc) => acc?.value).filter((value) => value);
    return !isEqual(newIds, selectedAccounts);
  }, [isDirty, tempAccounts, selectedAccounts]);

  const onCancel = () => {
    reset(filters);
    setTempAccounts(selectedAccounts);
    setExpanded(false);
  };

  const handleDownloadCSV = async () => {
    try {
      const hasAllOptionSelected = selectedAccounts.find((account) => account?.value === DefaultType.all);
      const payload = {
        bankAccountIds: hasAllOptionSelected
          ? bankAccounts?.map(({ id }) => id) || []
          : selectedAccounts.map(({ value }) => value),
        ...(search ? { search } : {}),
        ...formatFiltersForServer(filters),
        size: pagination?.totalElements,
      };

      const response = await getAdvancedTransactionSearchDocument(payload);
      forceDownload(window.URL.createObjectURL(response?.data), 'transactions.csv');
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const handleChangeSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setAdvancedSearchSearch(value));
  };

  const handleClearSearch = () => {
    dispatch(setAdvancedSearchSearch(''));
  };

  if (!bankAccounts?.length) {
    return <InsightsEmptyState isLoading={isLoading} />;
  }

  const isSaveDisabled = !tempAccounts.filter((account) => account?.value)?.length
    || (isEqual(tempAccounts.filter((account) => account?.value), selectedAccounts) && !isFormDirty);

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <AdvancedAccountFilter
        accounts={selectedAccounts}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onCancel}
        onResetAll={onResetAll}
        disabled={isSaveDisabled}
        filters={formatFiltersForHeader(filters)}
        isExpanded={expanded}
        onSetIsExpanded={setExpanded}
        hasResetAll={hasSavedFilters}
      >
        <Grid container>
          <Grid size={{ xs: 8 }}>
            <AccountsFilter
              hasAllOption
              selectedAccounts={tempAccounts}
              setSelectedAccounts={setTempAccounts}
            />
          </Grid>
          <Grid size={{ xs: 4 }}>
            <FilterContent
              register={register}
              errors={errors}
              control={control}
              watch={watch}
            />
          </Grid>
        </Grid>
      </AdvancedAccountFilter>

      <TotalTransactionsVolume />

      <Box display="flex" alignItems="cente" justifyContent="space-between" gap={4}>
        <SearchWrapper>
          <SearchField
            name="search-transactions"
            id="search-transactions"
            search={search}
            onClearSearch={handleClearSearch}
            onChange={handleChangeSearch}
            placeholder={intl.formatMessage({ id: 'placeholder.searchByDescriptionAndAmount' })}
          />
        </SearchWrapper>

        <CustomButton
          label={intl.formatMessage({ id: 'button.downloadCSV' })}
          id="btn-download-csv"
          variant="text"
          onClick={handleDownloadCSV}
          icon={<DownloadIcon size={20} />}
          sx={{ color: 'general.lightBlack' }}
        />
      </Box>

      <Transactions selectedAccounts={selectedAccounts} filters={filters} />
    </Box>
  );
};

export default AdvancedTransactionSearch;
